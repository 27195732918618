import { useState } from 'react';
import { Sparkles, ArrowRight, X, Newspaper, Scale, Bell } from 'lucide-react';
import logo from './assets/brieft-logo.png';


export default function LandingPage() {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <div className="min-h-screen flex flex-col" style={{ backgroundColor: '#FFF2C5' }}>
        {/* Header Section */}
        <header className="pt-20 pb-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center">
                <img 
                  src={logo} 
                  alt="Brieft Logo" 
                  className="w-48 md:w-64"
                  loading="eager"
                />
            </nav>
          </div>
        </header>
      
        {/* Main Content */}
        <main className="flex-grow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10">
            <section  className="text-center">
              <h1 className="text-3xl font-bold text-gray-900 my-6">
              Discover news that matters to you.
              </h1>
              <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                Be informed, get perspective, and stay updated.
              </p>
              
              <div className="flex flex-col items-center gap-6">
                <button
                  onClick={() => setShowForm(true)}
                  className="inline-flex items-center px-6 py-3 text-lg font-medium text-white 
                          bg-primary hover:bg-primary/80 transition-colors
                          shadow-lg hover:shadow-xl"
                >
                  <Sparkles className="mr-2 h-5 w-5" />
                  Join the Waitlist
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                
                <p className="text-gray-500 text-sm">
                  Be among the first to experience Brieft
                </p>
              </div>
            </section >

            {/* Features Section */}
            <section className="mt-20 grid md:grid-cols-3 gap-8 text-left">
              <article className="p-6 bg-white/80 rounded-lg">
                <div className="flex flex-col items-center mb-4">
                  <Newspaper className="h-8 w-8 text-primary" aria-hidden="true" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-3 text-center">Personalized Curation</h2>
                <p className="text-gray-600 text-center">Discover news tailored to your interests and preferences</p>
              </article>
              
              <article className="p-6 bg-white/80 rounded-lg">
                <div className="flex flex-col items-center mb-4">
                  <Scale className="h-8 w-8 text-primary" aria-hidden="true" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-3 text-center">Balanced Perspectives</h2>
                <p className="text-gray-600 text-center">Get a complete view with diverse news sources and viewpoints</p>
              </article>
              
              <article className="p-6 bg-white/80 rounded-lg">
                <div className="flex flex-col items-center mb-4">
                  <Bell className="h-8 w-8 text-primary" aria-hidden="true" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-3 text-center">Stay Updated</h2>
                <p className="text-gray-600 text-center">Follow topics and get updates as they develop</p>
              </article>
            </section>

          </div>
        </main>

        {/* Footer */}
        <footer className="py-8 bg-white border-t border-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <p className="text-gray-500 text-sm">
                © 2024 SpellLabs ApS. All rights reserved.
              </p>
            </div>
          </div>
        </footer>

        {/* Waitlist Form Modal */}
        {showForm && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            <div className="bg-white w-full max-w-3xl h-[95vh] relative rounded-lg shadow-xl">
              <button 
                onClick={() => setShowForm(false)}
                className="absolute top-4 right-4 p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                aria-label="Close modal"
              >
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSeWMBeLrEx0T9F_qwtaqtixXS4-ZQscM3MhsyFkevfjmN__eQ/viewform?embedded=true"
                className="w-full h-full border-0 m-0 rounded-lg"
                title="Brieft Waitlist Form"
                loading="lazy"
              >
                Loading...
              </iframe>
            </div>
          </div>
        )}

      </div>
  </>
  );
}